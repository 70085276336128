// trains_product_checkTheRemainingTrainTickets 车次余票查询
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://219.128.52.2:18765/swagger-ui.html#/product-controller/checkTheRemainingTrainTicketsUsingPOST
const consumer_trains_product_checkTheRemainingTrainTickets = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/product/checkTheRemainingTrainTickets',
        // urlSuffix: 'http://s3284376h4.qicp.vip/consumer/trains/product/checkTheRemainingTrainTickets',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_trains_product_checkTheRemainingTrainTickets;