import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import TrainCitySelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-city-selector/1.2.1/index.vue';
import consumer_trains_order_changeRule from '@/lib/data-service/haolv-default/consumer_trains_order_changeRule'
import consumer_trains_product_checkTheRemainingTrainTickets from '@/lib/data-service/haolv-default/consumer_trains_product_checkTheRemainingTrainTickets'
import consumer_trains_order_changeServiceTime  from '@/lib/data-service/haolv-default/consumer_trains_order_changeServiceTime'
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import CitySelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/1.2.2/index.vue";
import EvectionStandardExceed from '@/component/evectionStandardExceed/import_latest_version_module'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import TrainTimetable from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-timetable/1.0.0/index.vue'
import EpidemicPolicy from '@/page/admin/travel-book/train/component/epidemicPolicy/1.0.0/index.vue'
import moment from "moment";
export default {
    data() {
        return {
            // 车次票价--排序
            TicketPrice: 0,
            // 到达时间--排序
            ArrivalTime: 0,
            // 旅行耗时--排序
            TravelTime: 0,
            // 出发时间--排序
            DepartureTime: 0,
            // 展开、收起code
            openStowCode: null,
            paramsData: null,
            checkBoxSelected: [],
            modelSelected: [
                {
                    switch: false,
                    text: '不限',
                    motorcycleType: 0
                },
                {
                    switch: false,
                    text: 'C/G高铁',
                    motorcycleType: 1
                },
                {
                    switch: false,
                    text: 'D动车',
                    motorcycleType: 2
                },
                {
                    switch: false,
                    text: '普通列车',
                    motorcycleType: 3
                },
            ],
            setOutTimeSelected: [
                {
                    switch: false,
                    text: '不限',
                    fromTimeCode: 4
                },
                {
                    switch: false,
                    text: '00:00 - 06:00',
                    fromTimeCode: 0
                },
                {
                    switch: false,
                    text: '06:00 - 12:00',
                    fromTimeCode: 1
                },
                {
                    switch: false,
                    text: '12:00 - 18:00',
                    fromTimeCode: 2
                },
                {
                    switch: false,
                    text: '18:00 - 24:00',
                    fromTimeCode: 3
                },
            ],
            arriveTimeSelected: [
                {
                    switch: false,
                    text: '不限',
                    toTimeCode: 4
                },
                {
                    switch: false,
                    text: '00:00 - 06:00',
                    toTimeCode: 0
                },
                {
                    switch: false,
                    text: '06:00 - 12:00',
                    toTimeCode: 1
                },
                {
                    switch: false,
                    text: '12:00 - 18:00',
                    toTimeCode: 2
                },
                {
                    switch: false,
                    text: '18:00 - 24:00',
                    toTimeCode: 3
                },
            ],
            setOutStationSelected: [],
            arriveStationSelected: [],
            Originating: [
                {
                    switch: false,
                    text: '不限',
                    toStationType: 0
                },
                {
                    switch: false,
                    text: '始发',
                    toStationType: 1
                },
                {
                    switch: false,
                    text: '路过',
                    toStationType: 2
                },
            ],
            cityCanEdit: null,
            timeCanEdit: null,
            checked: false,
            loading: false,
            redirectType: '',
            evectionNo: '', // 出差预定的id
            evectionType: 1, // 1为个人出差， 2为出差单出差
            applyId: 0,   //申请单id
            orderNo: '',
            initStatus: 0,
            pickerOptions: {
                disabledDate: this.disabledDate
            },
            fromStation: {},
            toStation: {},
            oriFromStation: {},
            oriToStation: {},
            params: {
                fromStationCode: '',
                toStationCode: '',
                trainDate: ''
            },
            pickerTrainDate: [],
            pickerTrainDateTo: [],
            saveWeek: '',
            oriTrainDate: '',
            userList: [],
            isAllTrain: true, // 是否搜索全部类型
            onlySearchTrain: false, // 是否只搜高铁列车
            checkTrainList: [],
            showLoading: false, // loading时是否显示10分钟刷新的提示
            trainList: [
                {
                    trainSeatVoList: [
                        {
                            seatPrice: 0,
                        }
                    ]

                }
            ],
            trainListAll: [],
            daySpacing: '',
            isHasTicket: false, // 是否只显示有票
            reloadTicketFun: '',
            reloadNum: 0,
            loadingNum: 0,

            currentTrainInfo: {}, // 当前选定订票的火车票信息

            fromStationNum: 0,
            toStationNum: 0,

            userInfo: {
                realName: ''
            },
            travelCurrent: null, // 出差当前预设
            travelLVisible: false, // 出行人选择弹窗 -- 选择了出差单
            popSelectTravelUserList: [], // 出行人列表
            travelUserCheckList: [], // 选中的出行人
            travelLVisible2: false, // 出行人选择弹窗 -- 没选择出差单
            cascadeList: [], // 员工列表
            currentActiveList: [], //员工当前选中列表
            travelPolicy: '', // 差标标准
            evectionParams: {
                travelUserList: []
            },

            // 出差信息
            evectionInfo: {},
            ticketTrainList: [],
            flightList: [],
            hotelList: [],
            travelUserList: [],

            newTravelUser: [],
            pendingTravelUserList: [], // 待定的出行人列表

            hotelUserVisible: false,
            hotelForm: {
                roomNum: 0,
                checkInPerson: []
            },
            hotelRules: {},
            hotelUserList: [],
            loades: 10,
            query: {},
            timeData: [],
            trainOrderNo: null,
            application: {
                startStationList: [],
                endStationList: [],
            },
            //出差规则
            // 是否不限行程 0：否 1：是
            unLimitedJourney: 1,
            // 是否允许修改行程时间 0：否 1：是
            allowChangeJourneyTime: 0,
            // 帮订人数上限(为1表示只能帮自己预定)
            bookUpperBound: 1,
            // 因公出差规则 1：出差单审批 2：预订审批
            businessRuleControl: 0,
            // 是否可选临近城市 0：否 1：是
            changeEvectionCity: 0,
            // 全局改签政策：0:不限, 2:走审批流
            changePolicyType: 0,
            // 出差单是否必填：0:否, 1:是
            evectionRequired: 0,
            //是否长-短期出差标准 0：否 1：是
            ifLongShortTrip: 0,
            //保险管控 1：企业支付 2：个人支付
            insuranceControl: 0,
            // 多人出行差标 1：最低 2：最高
            policyControlRule: 0,
            // 全局退票政策：0:不限, 2:走审批流
            refundPolicyType: 0,
            // 是否不限行程 0：否 1：是
            unlimitedJourney: 0,
            //是否已选择出差单
            workTravelType: 1,
            journeyId: 0,


            minDate: new Date(),   //时间范围
            maxDate: new Date(new Date().getFullYear() + 10, 1, 1),   //时间范围
            cityChange: true,
            dateChange: true,
            startLoading: false,
            errorMsg: '',
        }
    },
    components: {
        TrainCitySelector,
        CitySelector,
        EvectionStandardExceed,
        TrainTimetable,
        EpidemicPolicy
    },
    created() {
    },
    mounted() {
        this.$refs.formTrainCitySelector.init({
            placeholder_text: `请选择出发城市`,
        });
    },
    async activated() {
        //信息回填完毕，调用接口请求数据
        this.initPage();
        this.refundChangePolicyGet();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        initEpidemicPolicy() {
            // 疫情防控政策提示--init
            this.$refs['epidemicPolicy'].init({
                startCityCode: this.params.fromStationCode,
                endCityCode: this.params.toStationCode
            })
        },
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get({clear_cache: false});
            this.evectionRequired = res.datas.evectionRequired;
            this.bookUpperBound = res.datas.bookUpperBound;
            this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime;
            this.unLimitedJourney = res.datas.unLimitedJourney;
            this.changeEvectionCity = res.datas.changeEvectionCity;
            this.businessRuleControl = res.datas.businessRuleControl;
        },
        disabledDate(time) {
            let oneData = moment(this.minDate).valueOf();
            let twoData = moment(this.maxDate).valueOf();
            return ((time.getTime() > twoData) || (time.getTime() < oneData)) || time.getTime() < moment().valueOf()
            // return time.getTime() < Date.now() - 8.64e7;
        },
        initPage() {
            this.evectionNo = this.$route.query.evectionNo ? this.$route.query.evectionNo : '';
            // this.applyId = this.$route.query.applyId ? parseInt(this.$route.query.applyId) : 0;
            this.orderNo = this.$route.query.orderNo;
            if (this.$route.query.fromStationCode) {
                this.fromStation = {
                    stationCode: this.$route.query.fromStationCode
                }
            } else {
                this.fromStation = {}
            }
            if (this.$route.query.toStationCode) {
                this.toStation = {
                    stationCode: this.$route.query.toStationCode
                }
            } else {
                this.toStation = {}
            }
            this.evectionType = parseInt(this.$route.query.evectionType);
            this.params.fromStationCode = this.$route.query.fromStationCode ? this.$route.query.fromStationCode : '';
            this.params.toStationCode = this.$route.query.toStationCode ? this.$route.query.toStationCode : '';
            this.params.trainDate = this.$route.query.time ? this.$route.query.time : this.$moment().locale('zh-cn').format('YYYY-MM-DD');
            this.oriFromStation = Object.assign({}, this.fromStation);
            this.oriToStation = Object.assign({}, this.toStation);
            this.oriTrainDate = this.params.trainDate;
            this.pickerTrainDate = this.$route.query.time ? this.query.time : this.$moment().locale('zh-cn').format('YYYY-MM-DD');
            //去程时间
            this.pickerTrainDateTo = this.$route.query.time;
            this.saveWeek = this.pickerTrainDateTo;

            this.userList = this.$route.query.userList;

            this.getChangeRule();


            this.getTrainList();
        },
        getChangeRule() {
            consumer_trains_order_changeRule({orderNo: this.orderNo}).then(res => {
                const info = res.datas;
                this.cityChange = info.cityChange;
                this.dateChange = info.dateChange;
                this.minDate = new Date(info.startTime);
                this.maxDate = new Date(info.endTime);
                this.errorMsg = info.msg;
            })
        },
        getTrainList() {
            this.pickerTrainDate = this.pickerTrainDateTo;
            // this.initEpidemicPolicy();
            if (this.params.fromStationCode === '' || this.params.fromStationCode === undefined) {
                this.$message({
                    message: '请先选择出发地',
                    type: 'warning'
                });
                return
            }
            if (this.params.toStationCode === '' || this.params.toStationCode === undefined) {
                this.$message({
                    message: '请先选择目的地',
                    type: 'warning'
                });
                return
            }

            if (this.pickerTrainDate === '' || this.pickerTrainDate === null) {
                this.$message({
                    message: '请先选择日期',
                    type: 'warning'
                });
                return
            }
            this.params.trainDate = this.pickerTrainDate;
            if (this.params.trainDate === '' || this.params.trainDate === null) {
                this.$message({
                    message: '请先选择日期',
                    type: 'warning',
                });
                return
            }
            let loading;
            loading = this.$loading({
                lock: true,
                text: this.showLoading ? '页面停留超过10分钟，正在为您重新查询' : '',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            this.params.fromStationCode = this.fromStation.stationCode;
            this.params.toStationCode = this.toStation.stationCode;
            this.params.trainDate = this.pickerTrainDateTo;
            consumer_trains_product_checkTheRemainingTrainTickets({
                evectionNo: this.evectionNo,
                applyId: this.applyId,
                ...this.params,
            }).then(res => {
                this.showLoading = false;
                this.oriTrainDate = this.params.trainDate;
                let resultList = res.resultList;
                this.oriTrainList = resultList;
                resultList.forEach(value => {
                    value.timeTable = [];
                    value.arriveDateText = this.$moment(value.arriveDate).format('YYYY-MM-DD hh:mm:ss')
                });
                this.trainList = this.trainFilter(this.oriTrainList);
                console.log('this.trainList', this.trainList);
                this.trainList.forEach((value,index) => {
                    // console.log(value);
                    this.$nextTick(() => {
                        let params = {
                            fromStationCode: value.fromStationCode,
                            toStationCode: value.fromStationCode,
                            trainCode: value.trainCode,
                            trainDate: this.$moment(value.arriveDate).format("YYYY-MM-DD"),
                        };
                        setTimeout(() => {
                            this.$refs.aTrainTimetable[index].init(params);
                        },1000)
                    });
                    value.trainSeatVoList.forEach(val => {
                        val.trainParams = {
                            evectionNo: this.evectionNo,
                            motorcycleType: value.motorcycleType,
                            seatName: val.seatName
                        }
                    })
                });
                this.DepartureTime = 1;
                this.trainListAll = this.trainList;
                this.oriFromStation = Object.assign({}, this.fromStation);
                this.oriToStation = Object.assign({}, this.toStation);

                let startStationList = [];
                let endStationList = [];
                this.oriTrainList.forEach(value => {
                    value.timeTable = [];
                    // 收集出发和目的地车站
                    let startStationItem = {
                        stationCode: value.fromStationCode,
                        stationName: value.fromStationName
                    };
                    if (!JSON.stringify(startStationList).includes(JSON.stringify(startStationItem))) {
                        startStationList.push(startStationItem)
                    }
                    let endStationItem = {
                        stationCode: value.toStationCode,
                        stationName: value.toStationName
                    };
                    if (!JSON.stringify(endStationList).includes(JSON.stringify(endStationItem))) {
                        endStationList.push(endStationItem)
                    }
                });
                this.application.startStationList = startStationList;
                this.application.endStationList = endStationList;
                this.setOutStationSelected = [];
                this.arriveStationSelected = [];

                this.setOutStationSelected.push({
                    switch: false,
                    text: '不限',
                    fromStationCode: null,
                });
                this.application.startStationList.forEach((item, index) => {
                    this.setOutStationSelected.push({
                        switch: false,
                        text: item.stationName,
                        fromStationCode: item.stationCode,
                    });
                });
                this.arriveStationSelected.push({
                    switch: false,
                    text: '不限',
                    toStationCode: null,
                });
                this.application.endStationList.forEach((item, index) => {
                    this.arriveStationSelected.push({
                        switch: false,
                        text: item.stationName,
                        toStationCode: item.stationCode,
                    });
                });

                loading.close();
                this.loadingNum++
            }).catch(() => {
                loading.close();
                this.showLoading = false;
                this.loadingNum++
            })
        },
        trainFilter(list) {
            let trainList = list;
            // 根据时间筛选 如果发车日期在一个月内，则不显示'预约抢'的车次，如果发车日期在一个月外，则不显示'预约和'抢票'
            /*let startDay = this.$moment()
            let searchDay = this.$moment(this.params.trainDate)
            this.daySpacing = searchDay.diff(startDay, 'days')
            let dayTrainItemList = []
            trainList.forEach(value => {
                if ((this.daySpacing + 1) === 29 || ((this.daySpacing + 1) <= 30 && value.isSale === '1') || ((this.daySpacing + 1) >= 30 && value.isSale === '0')) { // 预售期内且开售了 || 预售期外且没有开售
                    dayTrainItemList.push(value)
                }
            })
            trainList = dayTrainItemList*/

            // 筛选列车类型
            if (!this.isAllTrain && this.checkTrainList.length > 0) {
                let trainItemList = [];
                this.checkTrainList.forEach(value => {
                    trainList.forEach(val => {
                        if (value === val.motorcycleType) {
                            trainItemList.push(val)
                        }
                    })
                });
                trainList = trainItemList
            }

            // 筛选是否显示有票的
            if ((this.daySpacing + 1 <= 30) && this.isHasTicket) {
                let trainItemList = [];
                trainList.forEach(value => {
                    let hasTicket = false;
                    value.trainSeatVoList.forEach(val => {
                        if (val.seatInventory > 0) {
                            hasTicket = true
                        }
                    });
                    if (hasTicket) {
                        trainItemList.push(value)
                    }
                });
                trainList = trainItemList
            }
            return trainList
        },
        setSort() {
            const __this = this;
            let trainList = __this.trainList;

            let timeFormMinute = function (val) {
                let timeVal = val.split(':');
                let hour = parseInt(timeVal[0]) * 60;
                let minute = parseInt(timeVal[1]);
                return hour + minute
            };
            let timeToMinute = function (val) {
                let timeVal = val.toTime.split(':');
                let hour = timeVal[0] = (Number(timeVal[0]) +  Number(val.daysTrip*24)) * 60;
                let minute = Number(parseInt(timeVal[1]));
                return hour + minute
            };
            let DepartureTimeJust = function (x, y) { // 按出发时间正序排列
                let xFromTime = timeFormMinute(x.fromTime);
                let yFromTime = timeFormMinute(y.fromTime);
                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            };
            let DepartureTimeBack = function (x, y) { // 按出发时间反序排列
                let xFromTime = timeFormMinute(x.fromTime);
                let yFromTime = timeFormMinute(y.fromTime);
                if (xFromTime > yFromTime) {
                    return -1;
                } else if (xFromTime < yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            };
            let TravelTimeJust = function (x, y) { // 按出发时间排列短-->长
                let xBox = x.spanTime.split('时');
                let xSpanTime = 0;
                if (xBox.length == 2) {
                    xSpanTime = xBox[0]*100 + Number(xBox[1].replace('分',''));
                }
                if (xBox.length == 1) {
                    xSpanTime = Number(xBox[0].replace('分',''));
                }
                let yBox = y.spanTime.split('时');
                let ySpanTime = 0;
                if (yBox.length == 2) {
                    ySpanTime = yBox[0]*100 + Number(yBox[1].replace('分',''));
                }
                if (yBox.length == 1) {
                    ySpanTime = Number(yBox[0].replace('分',''));
                }
                if (xSpanTime < ySpanTime) {
                    return -1;
                } else if (xSpanTime > ySpanTime) {
                    return 1;
                } else {
                    return 0;
                }
            };
            let TravelTimeBack = function (x, y) { // 按出发时间排列长-->短
                let xBox = x.spanTime.split('时');
                let xSpanTime = 0;
                if (xBox.length == 2) {
                    xSpanTime = xBox[0]*100 + Number(xBox[1].replace('分',''));
                }
                if (xBox.length == 1) {
                    xSpanTime = Number(xBox[0].replace('分',''));
                }
                let yBox = y.spanTime.split('时');
                let ySpanTime = 0;
                if (yBox.length == 2) {
                    ySpanTime = yBox[0]*100 + Number(yBox[1].replace('分',''));
                }
                if (yBox.length == 1) {
                    ySpanTime = Number(yBox[0].replace('分',''));
                }
                if (xSpanTime > ySpanTime) {
                    return -1;
                } else if (xSpanTime < ySpanTime) {
                    return 1;
                } else {
                    return 0;
                }
            };
            let ArrivalTimeJust = function (x, y) { // 按到达时间正序排列
                let xToTime = timeToMinute(x);
                let yToTime = timeToMinute(y);
                if (xToTime < yToTime) {
                    return -1;
                } else if (xToTime > yToTime) {
                    return 1;
                } else {
                    return 0;
                }
            };
            let ArrivalTimeBack = function (x, y) { // 按到达时间反序排列
                let xToTime = timeToMinute(x);
                let yToTime = timeToMinute(y);
                if (xToTime > yToTime) {
                    return -1;
                } else if (xToTime < yToTime) {
                    return 1;
                } else {
                    return 0;
                }
            };
            let TicketPriceJust = function (x, y) { // 按车次票价正序排列
                let xNum = Number(x.trainSeatVoList[0].seatPrice);
                let yNum = Number(y.trainSeatVoList[0].seatPrice);
                if (xNum < yNum) {
                    return -1;
                } else if (xNum > yNum) {
                    return 1;
                } else {
                    return 0;
                }
            };
            let TicketPriceBack = function (x, y) { // 按车次票价反序排列
                let xNum = Number(x.trainSeatVoList[0].seatPrice);
                let yNum = Number(y.trainSeatVoList[0].seatPrice);
                if (xNum > yNum) {
                    return -1;
                } else if (xNum < yNum) {
                    return 1;
                } else {
                    return 0;
                }
            };

            if (this.DepartureTime != 0) {
                if (this.DepartureTime == 1) {
                    trainList.sort(DepartureTimeJust);
                }
                if (this.DepartureTime == 2) {
                    trainList.sort(DepartureTimeBack);
                }
            } else if (this.TravelTime != 0) {
                if (this.TravelTime == 1) {
                    trainList.sort(TravelTimeJust);
                }
                if (this.TravelTime == 2) {
                    trainList.sort(TravelTimeBack);
                }
            } else if (this.ArrivalTime != 0) {
                if (this.ArrivalTime == 1) {
                    trainList.sort(ArrivalTimeJust);
                }
                if (this.ArrivalTime == 2) {
                    trainList.sort(ArrivalTimeBack);
                }
            } else if (this.TicketPrice != 0) {
                if (this.TicketPrice == 1) {
                    trainList.sort(TicketPriceJust);
                }
                if (this.TicketPrice == 2) {
                    trainList.sort(TicketPriceBack);
                }
            }

            __this.trainList = trainList;
        },
        filterWeek(val) {
            if (val === '' || !val) {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        changeDepDate(val) {
            //将去程选择的日期录入query中
            // let query = this.$route.query;
            // const key = 'params';
            // const params = aes_decrypt({
            //     data: query.params,
            //     key,
            // });
            // query = Object.assign({}, query, JSON.parse(params));
            // query.time = val;
            //
            // const encrypted = aes_encrypt({
            //     data: JSON.stringify(query),
            //     key,
            // });
            //
            // this.$route.query.params = encrypted;

        },
        checkBoxModel(data) {
            //区分选中和取消状态
            if (data.switch) {
                //选中操作--录入信息进行已选数据回显
                this.checkBoxSelected.push({
                    text: `车型：${data.text}`,
                    motorcycleType: data.motorcycleType,
                    fromTimeCode: null,
                    toTimeCode: null,
                    fromStationCode: null,
                    toStationCode: null,
                    toStationType: null,
                });
            } else {
                //取消操作--删除数据
                this.checkBoxSelected.forEach((item, index) => {
                    if (item.text == `车型：${data.text}`) {
                        this.checkBoxSelected.splice(index, 1);
                    }
                });
            }
            //不限选项操作
            if (data.text == '不限') {
                let box = [];
                //将不限以外选中取消选中
                this.modelSelected.forEach((item, index) => {
                    if (index != 0) {
                        item.switch = false;
                    }
                });
                //将不限选项信息暂时保存
                this.checkBoxSelected.forEach((CItem, CIndex) => {
                    if (CItem.motorcycleType == null) {
                        box.push(CItem);
                    }
                });
                //录入不限选项信息进行回显
                this.checkBoxSelected = box;
            } else {
                //将不限选项取消选中
                this.modelSelected[0].switch = false;
                //删除不限选项信息
                this.checkBoxSelected.forEach((item, index) => {
                    if (item.text == `车型：不限`) {
                        this.checkBoxSelected.splice(index, 1);
                    }
                });
            }
            //进行筛选
            this.screenMethod();
        },
        screenMethod() {
            let data = this.trainListAll;
            let box = [];
            let motorcycleTypeBox = [];
            let fromTimeCode = [];
            let toTimeCode = [];
            let fromStationCode = [];
            let toStationCode = [];
            let toStationType = [];

            this.checkBoxSelected.forEach((item, index) => {
                if (item.text == '到达时段：不限') {
                    data.forEach((CItem, CIndex) => {
                        motorcycleTypeBox.push(CItem);
                    });
                } else if (item.motorcycleType != null) {
                    data.forEach((CItem, CIndex) => {
                        if (CItem.motorcycleType == item.motorcycleType) {
                            motorcycleTypeBox.push(CItem);
                        }
                    });
                }
                if (item.text == '出发时段：不限') {
                    data.forEach((CItem, CIndex) => {
                        fromTimeCode.push(CItem);
                    });
                } else if (item.fromTimeCode != null) {
                    data.forEach((CItem, CIndex) => {
                        if (CItem.fromTimeCode == item.fromTimeCode) {
                            fromTimeCode.push(CItem);
                        }
                    });
                }
                if (item.text == '车型：不限') {
                    data.forEach((CItem, CIndex) => {
                        toTimeCode.push(CItem);
                    });
                } else if (item.toTimeCode != null) {
                    data.forEach((CItem, CIndex) => {
                        if (CItem.toTimeCode == item.toTimeCode) {
                            toTimeCode.push(CItem);
                        }
                    });
                }
                if (item.text == '出发车站：不限') {
                    data.forEach((CItem, CIndex) => {
                        fromStationCode.push(CItem);
                    });
                } else if (item.fromStationCode != null) {
                    data.forEach((CItem, CIndex) => {
                        if (CItem.fromStationCode == item.fromStationCode) {
                            fromStationCode.push(CItem);
                        }
                    });
                }
                if (item.text == '到达车站：不限') {
                    data.forEach((CItem, CIndex) => {
                        toStationCode.push(CItem);
                    });
                } else if (item.toStationCode != null) {
                    data.forEach((CItem, CIndex) => {
                        if (CItem.toStationCode == item.toStationCode) {
                            toStationCode.push(CItem);
                        }
                    });
                }
                if (item.text == '是否始发站：不限') {
                    data.forEach((CItem, CIndex) => {
                        toStationType.push(CItem);
                    });
                } else if (item.toStationType != null) {
                    data.forEach((CItem, CIndex) => {
                        // console.log('CItem',CItem.toStationType,'item',item.toStationType);
                        if (
                            (CItem.toStationType == 1 && item.toStationType == 1) ||
                            (CItem.toStationType == 2 && item.toStationType == 2) ||
                            item.toStationType == 0
                        ) {
                            toStationType.push(CItem);
                        }
                    });
                }
            });

            this.checkBoxSelected.forEach((item, index) => {
                if (item.motorcycleType != null || item.text == '到达时段：不限') {
                    box.push(motorcycleTypeBox);
                }
                if (item.fromTimeCode != null || item.text == '出发时段：不限') {
                    box.push(fromTimeCode);
                }
                if (item.toTimeCode != null || item.text == '车型：不限') {
                    box.push(toTimeCode);
                }
                if (item.fromStationCode != null || item.text == '出发车站：不限') {
                    box.push(fromStationCode);
                }
                if (item.toStationCode != null || item.text == '到达车站：不限') {
                    box.push(toStationCode);
                }
                if (item.toStationType != null || item.text == '是否始发站：不限') {
                    box.push(toStationType);
                }
            });

            let obj = {};
            let dataBox = [];
            box.forEach((item, index) => {
                if (!box[index - 1]) {
                    dataBox = box[index];
                } else {
                    const test = box[index - 1].map(item => item.trainNo);
                    dataBox = box[index].filter(item => test.includes(item.trainNo));
                }
            });

            if (this.checkBoxSelected.length == 0) {
                this.trainList = this.trainListAll;
            } else {
                this.trainList = dataBox;
            }
        },
        checkBoxSetOutTime(data) {
            if (data.switch) {
                this.checkBoxSelected.push({
                    text: `出发时段：${data.text}`,
                    motorcycleType: null,
                    fromTimeCode: data.fromTimeCode,
                    toTimeCode: null,
                    fromStationCode: null,
                    toStationCode: null,
                    toStationType: null,
                });
            } else {
                this.checkBoxSelected.forEach((item, index) => {
                    if (item.text == `出发时段：${data.text}`) {
                        this.checkBoxSelected.splice(index, 1);
                    }
                });
            }
            if (data.text == '不限') {
                let box = [];
                this.setOutTimeSelected.forEach((item, index) => {
                    if (index != 0) {
                        item.switch = false;
                    }
                });
                this.checkBoxSelected.forEach((CItem, CIndex) => {
                    if (CItem.fromTimeCode == null) {
                        box.push(CItem);
                    }
                });
                this.checkBoxSelected = box;
            } else {
                this.setOutTimeSelected[0].switch = false;
                this.checkBoxSelected.forEach((item, index) => {
                    if (item.text == `出发时段：不限`) {
                        this.checkBoxSelected.splice(index, 1);
                    }
                });
            }
            this.screenMethod();
        },
        checkBoxArriveTime(data) {
            if (data.switch) {
                this.checkBoxSelected.push({
                    text: `到达时段：${data.text}`,
                    motorcycleType: null,
                    fromTimeCode: null,
                    toTimeCode: data.toTimeCode,
                    fromStationCode: null,
                    toStationCode: null,
                    toStationType: null,
                });
            } else {
                this.checkBoxSelected.forEach((item, index) => {
                    if (item.text == `到达时段：${data.text}`) {
                        this.checkBoxSelected.splice(index, 1);
                    }
                });
            }
            if (data.text == '不限') {
                let box = [];
                this.arriveTimeSelected.forEach((item, index) => {
                    if (index != 0) {
                        item.switch = false;
                    }
                });
                this.checkBoxSelected.forEach((CItem, CIndex) => {
                    if (CItem.toTimeCode == null) {
                        box.push(CItem);
                    }
                });
                this.checkBoxSelected = box;
            } else {
                this.arriveTimeSelected[0].switch = false;
                this.checkBoxSelected.forEach((item, index) => {
                    if (item.text == `到达时段：不限`) {
                        this.checkBoxSelected.splice(index, 1);
                    }
                });
            }
            this.screenMethod();
        },
        checkBoxSetOutStation(data) {
            if (data.switch) {
                this.checkBoxSelected.push({
                    text: `出发车站：${data.text}`,
                    motorcycleType: null,
                    fromTimeCode: null,
                    toTimeCode: null,
                    fromStationCode: data.fromStationCode,
                    toStationCode: null,
                    toStationType: null,
                });
            } else {
                this.checkBoxSelected.forEach((item, index) => {
                    if (item.text == `出发车站：${data.text}`) {
                        this.checkBoxSelected.splice(index, 1);
                    }
                });
            }
            if (data.text == '不限') {
                let box = [];
                this.setOutStationSelected.forEach((item, index) => {
                    if (index != 0) {
                        item.switch = false;
                    }
                });
                this.checkBoxSelected.forEach((CItem, CIndex) => {
                    if (CItem.fromStationCode == null) {
                        box.push(CItem);
                    }
                });
                this.checkBoxSelected = box;
            } else {
                this.setOutStationSelected[0].switch = false;
                this.checkBoxSelected.forEach((item, index) => {
                    if (item.text == `出发车站：不限`) {
                        this.checkBoxSelected.splice(index, 1);
                    }
                });
            }
            this.screenMethod();
        },
        checkBoxArriveStation(data) {
            if (data.switch) {
                this.checkBoxSelected.push({
                    text: `到达车站：${data.text}`,
                    motorcycleType: null,
                    fromTimeCode: null,
                    toTimeCode: null,
                    fromStationCode: null,
                    toStationCode: data.toStationCode,
                    toStationType: null,
                });
            } else {
                this.checkBoxSelected.forEach((item, index) => {
                    if (item.text == `到达车站：${data.text}`) {
                        this.checkBoxSelected.splice(index, 1);
                    }
                });
            }
            if (data.text == '不限') {
                let box = [];
                this.arriveStationSelected.forEach((item, index) => {
                    if (index != 0) {
                        item.switch = false;
                    }
                });
                this.checkBoxSelected.forEach((CItem, CIndex) => {
                    if (CItem.toStationCode == null) {
                        box.push(CItem);
                    }
                });
                this.checkBoxSelected = box;
            } else {
                this.arriveStationSelected[0].switch = false;
                this.checkBoxSelected.forEach((item, index) => {
                    if (item.text == `到达车站：不限`) {
                        this.checkBoxSelected.splice(index, 1);
                    }
                });
            }
            this.screenMethod();
        },
        checkBoxOriginating(data) {
            //循环数据，进行操作
            this.Originating.forEach((item, index) => {
                if (data.toStationType == item.toStationType) {
                    if (data.switch) {
                        this.checkBoxSelected.push({
                            text: `是否始发站：${item.text}`,
                            motorcycleType: null,
                            fromTimeCode: null,
                            toTimeCode: null,
                            fromStationCode: null,
                            toStationCode: null,
                            toStationType: item.toStationType,
                        });
                    } else if (!data.switch) {
                        this.checkBoxSelected.forEach((CItem, CIndex) => {
                            if (CItem.text == `是否始发站：${item.text}`) {
                                this.checkBoxSelected.splice(CIndex, 1);
                            }
                        });
                    }
                } else {
                    item.switch = false;
                    this.checkBoxSelected.forEach((CItem, CIndex) => {
                        if (CItem.text == `是否始发站：${item.text}`) {
                            this.checkBoxSelected.splice(CIndex, 1);
                        }
                    });
                }
            });
            this.screenMethod();
        },
        clickDelete(item, index) {
            this.checkBoxSelected.splice(index, 1);
            if (item.motorcycleType != null || item.text == '到达时段：不限') {
                this.modelSelected.forEach((CItem, CIndex) => {
                    if (item.motorcycleType == CItem.motorcycleType) {
                        CItem.switch = false;
                    }
                })
            } else if (item.fromTimeCode != null || item.text == '出发时段：不限') {
                this.setOutTimeSelected.forEach((CItem, CIndex) => {
                    if (item.fromTimeCode == CItem.fromTimeCode) {
                        CItem.switch = false;
                    }
                })
            } else if (item.toTimeCode != null || item.text == '车型：不限') {
                this.arriveTimeSelected.forEach((CItem, CIndex) => {
                    if (item.toTimeCode == CItem.toTimeCode) {
                        CItem.switch = false;
                    }
                })
            } else if (item.fromStationCode != null || item.text == '出发车站：不限') {
                this.setOutStationSelected.forEach((CItem, CIndex) => {
                    if (item.fromStationCode == CItem.fromStationCode) {
                        CItem.switch = false;
                    }
                })
            } else if (item.toStationCode != null || item.text == '到达车站：不限') {
                this.arriveStationSelected.forEach((CItem, CIndex) => {
                    if (item.toStationCode == CItem.toStationCode) {
                        CItem.switch = false;
                    }
                })
            } else if (item.toStationType != null || item.text == '是否始发站：不限') {
                this.Originating.forEach((CItem, CIndex) => {
                    if (item.toStationType == CItem.toStationType) {
                        CItem.switch = false;
                    }
                })
            }
            this.screenMethod();
        },
        // 出发时间--排序--click
        DepartureTimeClick(){
            // 清除其他筛选条件
            this.TravelTime = 0;
            this.ArrivalTime = 0;
            this.TicketPrice = 0;
            if (this.DepartureTime == 0) {
                this.DepartureTime = 1;
                this.setSort();
                return
            }
            if (this.DepartureTime == 1) {
                this.DepartureTime = 2;
                this.setSort();
                return
            }
            if (this.DepartureTime == 2) {
                this.DepartureTime = 1;
                this.setSort();
                return
            }
        },
        // 旅行耗时--排序--click
        TravelTimeClick(){
            // 清除其他筛选条件
            this.DepartureTime = 0;
            this.ArrivalTime = 0;
            this.TicketPrice = 0;
            if (this.TravelTime == 0) {
                this.TravelTime = 1;
                this.setSort();
                return
            }
            if (this.TravelTime == 1) {
                this.TravelTime = 2;
                this.setSort();
                return
            }
            if (this.TravelTime == 2) {
                this.TravelTime = 1;
                this.setSort();
                return
            }
        },
        // 到达时间--排序--click
        ArrivalTimeClick(){
            // 清除其他筛选条件
            this.DepartureTime = 0;
            this.TravelTime = 0;
            this.TicketPrice = 0;
            if (this.ArrivalTime == 0) {
                this.ArrivalTime = 1;
                this.setSort();
                return
            }
            if (this.ArrivalTime == 1) {
                this.ArrivalTime = 2;
                this.setSort();
                return
            }
            if (this.ArrivalTime == 2) {
                this.ArrivalTime = 1;
                this.setSort();
                return
            }
        },
        // 车次票价--排序--click
        TicketPriceClick(){
            // 清除其他筛选条件
            this.DepartureTime = 0;
            this.TravelTime = 0;
            this.ArrivalTime = 0;
            if (this.TicketPrice == 0) {
                this.TicketPrice = 1;
                this.setSort();
                return
            }
            if (this.TicketPrice == 1) {
                this.TicketPrice = 2;
                this.setSort();
                return
            }
            if (this.TicketPrice == 2) {
                this.TicketPrice = 1;
                this.setSort();
                return
            }
        },
        // 展开、收起方法
        openStowClick(index){
            if (this.openStowCode == index) {
                this.openStowCode = null;
            } else {
                this.openStowCode = index;
            }
        },



        async reserve(val, index, sIndex) {
            let [err, res] = await awaitWrap(consumer_trains_order_changeServiceTime())
            if (err) {
                return
            }
            if (!res.datas.status) {
                this.$message({
                    type: "warning",
                    message: res.datas.msg
                })
                return
            }
            let query = {
                evectionNo: this.evectionNo,
                evectionType: this.evectionType,
                orderNo: this.orderNo,
                userList: this.userList,
                fromStationCode: val.fromStationCode,
                toStationCode: val.toStationCode,
                trainDate: this.$moment(val.trainStartDate).format('YYYY-MM-DD'),
                trainNo: val.trainCode,
                applyId: this.applyId,
                seatIndex: sIndex,
            }
            this.$router.push({
                name: 'admin-train-change-submit',
                query
            })
        },
    }
}
