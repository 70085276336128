// consumer_trains_common_getEpidemicPolicy
const __request = require(`./__request/__request_contentType_json`)
const consumer_trains_common_getEpidemicPolicy = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/trains/common/getEpidemicPolicy',
        data: data
    }
    return __request(pParameter)
}
export default consumer_trains_common_getEpidemicPolicy
