import consumer_trains_common_getEpidemicPolicy from '@/lib/data-service/haolv-default/consumer_trains_common_getEpidemicPolicy'
import {handlePromise} from '@/page/admin/company-management/staff-management/common/unit'
export default {
    data() {
        return {
            show: false,
            startCityInfo: null,
            endCityInfo: null,
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        _close() {
            this.show = false
        },
        _showPopup() {
            this.show = true
        },
        init(params) {
            let startCityCode = params.startCityCode;
            let endCityCode = params.endCityCode;
            const promiseList = [consumer_trains_common_getEpidemicPolicy({cityCode: startCityCode}), consumer_trains_common_getEpidemicPolicy({cityCode: endCityCode})]
            Promise.all(handlePromise(promiseList)).then(res => {
                if (res[0]) {
                    this.startCityInfo = res[0].datas
                } else {
                    this.startCityInfo = null
                }
                if (res[1]) {
                    this.endCityInfo = res[1].datas
                } else {
                    this.endCityInfo = null
                }
                if (this.startCityInfo || this.endCityInfo) {
                    this._showPopup()
                    this.$emit('getPolicy')
                }

            })
        },
    }
}